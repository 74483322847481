<template>
  <div id="wrapper">
    <!-- Sidebar -->
    <ul class="navbar-nav sidebar sidebar-dark accordion" id="accordionSidebar" >
      <!-- Sidebar - Brand -->
      <router-link class="sidebar-brand d-flex align-items-center justify-content-center" to="/afcfta_link/dashboard" style="height:150px">
        <div class="sidebar-brand-icon">
          <img height="80" src="../../assets/AFCFTA NUMBER LOGO3.png" alt="">
        </div>
      </router-link>
      <hr class="sidebar-divider my-0">
      <li class="nav-item active my-3">
        <router-link class="nav-link" to="/afcfta_link/dashboard">
          <i class="fas fa-fw fa-tachometer-alt"></i>
          <span> Dashboard</span></router-link>
      </li>
      <hr class="sidebar-divider">
      <li class="nav-item"  v-if="currentEntity.entity">
        <router-link class="nav-link" :to="`/afcfta_link/entity/${currentEntity.entity.id}/all-products`" >
          <i class="fas fa-fw fa-list"></i>
          <span> All Products</span>
        </router-link>
        <!-- <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">products:</h6>
            <router-link class="collapse-item" to="/afcfta_link/dashboard/newproduct">New Products</router-link>
            <router-link class="collapse-item" to="/afcfta_link/dashboard/my-products">My Products</router-link>
            <router-link class="collapse-item" to="/afcfta_link/dashboard/all-products">All Products</router-link>
          </div>
        </div> -->
      </li>
      <li class="nav-item"  v-if="currentEntity.entity">
        <router-link class="nav-link" :to="`/afcfta_link/entity/${currentEntity.entity.id}/newproduct`" >
          <i class="fas fa-fw fa-plus"></i>
          <span> New Product</span>
        </router-link>
      </li>
      <li class="nav-item"  v-if="currentEntity.entity">
        <router-link class="nav-link" :to="`/afcfta_link/entity/${currentEntity.entity.id}/my-products`">
          <i class="fa fa-bars"></i>
          <span>  My Products</span>
        </router-link>
      </li>
      <li class="nav-item"  v-if="currentEntity.entity">
        <router-link class="nav-link" :to="`/afcfta_link/entity/${currentEntity.entity.id}/certifications`">
          <i class="fa fa-th-large"></i>
          <span>  Certificate A Certificate</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" to="/afcfta_link/dashboard/cert">
          <i class="fa fa-columns"></i>
          <span> Administrative Declaration</span>
        </router-link>
      </li>
      
      <hr class="sidebar-divider">
    
    </ul>
    <div id="content-wrapper" class="d-flex flex-column">
      <!-- Main Content -->
      <div id="content">
        <!-- Topbar -->
        <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
          <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
            <i class="fa fa-bars"></i>
          </button>
          <!-- Topbar Navbar -->
          <ul class="navbar-nav ml-auto">
            <div class="topbar-divider d-none d-sm-block"></div>
            <li class="nav-item dropdown no-arrow">
              <a class="nav-link dropdown-toggle" href="#" id="userDropdown" role="button" 
                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span class="mr-2 d-none d-lg-inline text-gray-600 small">Welcome ! {{currentUser.individual.username}}</span>
                <img class="img-profile rounded-circle"  src="https://source.unsplash.com/QAB-WJcbgJk/60x60">
              </a>
              <div class="dropdown-menu dropdown-menu-right shadow animated--grow-in" aria-labelledby="userDropdown">
                <a class="dropdown-item" href="#">
                  <i class="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                  Profile
                </a>
                <a class="dropdown-item" href="#">
                  <i class="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                  Settings
                </a>
                <a class="dropdown-item" href="#">
                  <i class="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                  Activity Log
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                  <i class="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                  Logout
                </a>
              </div>
            </li>

          </ul>

        </nav>
        <div class="container-fluid">
          <!-- DASBOARD CONTENT GOES HERE -->
          <router-view> </router-view>
        </div>
      
      </div>
      <footer class="sticky-footer bg-white">
        <div class="container my-auto">
          <div class="copyright text-center my-auto">
            <span>Copyright &copy; AfCFTA_LINK {{fullYear}}</span>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name:'layout',
  data() {
    return {
    }
  },
  computed: {
    currentEntity() {
      return this.$store.getters.currentEntity 
    },
    currentUser() {
      return this.$store.getters.currentUser
    },
    fullYear() {
      return new Date().getFullYear()
    }
  },
  created() {
    // console.log(this.currentUser)
  }
}
</script>

<style scoped>
  .sidebar {
    background: #ba2428
  } 
</style>