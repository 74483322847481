<template>
 <div fluid class="container main-content-container card px-4">
    <!-- Page Header -->
    <div no-gutters class="row page-header py-4">
      <div  col sm="4" class=" col text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Dashboard</span>
        
      </div>
    </div>
    <div class="container">
      <div class="card-deck text-white py-5">
        <!-- <router-link to="/divisions" class="card card-small card-link text-center" > -->
          <div class="card-block card-body text-white" style="background:linear-gradient(40deg,#ff2020, #05ffa3);">
            <h4 class="card-title text-capitalize text-white">My Entities</h4>
            <p class="card-text display-3">
              {{currentUser.entities.length}}
            </p>
          </div>
          <!-- <div class="card-footer bg-info text-white">
            <div class="row">
    
            </div>
          </div> -->
        <!-- </router-link> -->
          <div class="card-block card-body">
            <h4 class="card-title text-dark">Select entity you want to manage</h4>
            <p class="card-text display text-white">
              <select class="form-control" @change="getCurrentEntity" name="" id="" v-model="entity">
                  <option @select="getCurrentEntity(entity)" v-for="(entity, key) in currentUser.entities" :key="key" :value="entity">{{entity.entity.name}}</option>
              </select>
            </p>
          </div>
          <!-- <div class="card-footer bg-warning text-white">
            <div class="row">
            </div>

          </div> -->
  
        <!-- <router-link to="/users" class="card card-link card-small text-center">
          <div class="card-block card-body bg-danger">
            <h4 class="card-title text-uppercase text-white">Users</h4>
            <p class="card-text display-3 text-white">
              44
            </p>
          </div>
          <div class="card-footer bg-danger text-white">
    
          </div>
        </router-link> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name:'home',
  data() {
    return {
      entities:null,
      entity:null
    }
  },
  methods: {
    getCurrentEntity() {
      this.$store.dispatch('setCurrentEntity', this.entity)
      window.location.reload()
    }
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    }
  },
  created() {
  
  }
}
</script>

<style>
.bg-info{
  background: #05ffa3 !important;
}
select option {
  padding: 20px !important;
}
</style>